import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.updateHeader(this.getSelectedGameName());
  }

  changeHeader(e) {
    if (e.detail?.name) {
      this.updateHeader(e.detail.name);
    } else {
      this.updateHeader("");
    }
  }

  getSelectedGameName() {
    const input = document.getElementById("selectedGameName");
    
    return input ? input.value.trim() : "";
  }

  updateHeader(gameName) {
    if (gameName) {
      this.element.innerHTML = `for ${this.createSpan(gameName)}`;
    } else {
      this.element.innerHTML = "";
    }
  }

  createSpan(text) {
    return `<span class="capitalize">"${text}"</span>`;
  }
}