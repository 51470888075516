import Carousel from "stimulus-carousel";

export default class extends Carousel {
  static targets = ["btnNext", "btnPrev"];

  connect() {
    super.connect();
  }

  get defaultOptions() {
    return {
      slidesPerView: "auto",
      slidesPerGroup: 2,
      allowTouchMove: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
      centerInsufficientSlides: true,
      navigation: {
        nextEl: this.btnNextTarget,
        prevEl: this.btnPrevTarget,
      },
      breakpoints: {
        768: {
          allowTouchMove: false,
        },
      },
      initialSlide: this.getActiveSlideIndex(),
    };
  }

  getActiveSlideIndex() {
    const activeSlide = document.querySelector(".swiper-slide.active");
    
    if (!activeSlide) return 0;

    const index = Array.from(activeSlide.parentNode.children).indexOf(activeSlide);
    
    return index;
  }
}