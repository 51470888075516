import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ["select"]

  connect() {
    this.initSelect()
  }

  initSelect(){
    const options = {
      sortField: [{field: '$order'}]
      // sortField: {
      //   field: "text",
      //   direction: "desc"
      // }
    }

    this.select = new TomSelect(this.selectTarget, options)
    this.select.on('item_add', function (value, data) {
      const url = `/promotions/dates?game_id=${value}`
      fetch(url, {
        headers: {
          Accept: "text/vnd.turbo-stream.html"
        }
      })
          .then(r => r.text())
          .then(html => Turbo.renderStreamMessage(html))
    })

    this.select.on('change', (value) => {
      this.dispatchChangeEvent(value)
    })

    
  }

  dispatchChangeEvent(value) {
    const event = new CustomEvent('select:change', {
      detail: { value: value },
      bubbles: true,
      cancelable: true
    })

    window.dispatchEvent(event)
  }
}
